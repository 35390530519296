<template>
    <div>
        <nav-bar title="部门排名" url="/sale"></nav-bar>
        <div class="rank">
            <img :src="rank" @click="preview(rank)" />
        </div>
    </div>
</template>

<script>
    import {
        sale_rank,
    } from '@/api/sale.js';
    import {
        ImagePreview
    } from 'vant';
    export default {
        name: 'sale_rank',
        data() {
            return {
                rank: '',
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                sale_rank({
                    loading: true,
                }).then(res => {
                    if (res.code == 1) {
                        this.rank = res.data;
                    }
                });
            },

            //图片预览
            preview(img) {
                ImagePreview({
                    images: [img],
                    startPosition: 0
                });
            },
        }
    }
</script>

<style scoped>
    .rank {
        width: calc(100% - 30px);
        margin: 15px;
    }

    .rank img {
        width: 100%;
    }
</style>